import {Dispatch, SetStateAction} from 'react'

export interface PlaybackId {
	id: string;
	playbackPolicy: PlaybackPolicy;
}

export type PlaybackPolicy = "public" | "signed";

export interface NewVideoOptions {
	playbackPolicy: PlaybackPolicy[];
	mp4Support: boolean;
	normalizeAudio: boolean;
	tags: string[];
	passthrough?: string;
}


export type ID = undefined | null | string

export type PaginationState = {
  page: number
  totalPages: number
  totalItems: number
  limit: number
}

export type SortState = {
  sortBy?: string
  orderBy?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  name?: string
}

export type Response<T> = {
  data?: T
  pagination?: PaginationState
  errors?: {
    type: string
    message?: string
    messages?: string[]
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 25,
  totalPages: 0,
  totalItems: 0,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<T> | undefined
  isRefetching: boolean
  refetch: () => void
  isLoading: boolean
  query: string
  isError: boolean
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: '', isError: false, isRefetching: false}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}

import axios, { AxiosResponse } from 'axios'
import { AuthModel, RequestPasswordResetDto, ResetPasswordDto } from './_models'
import { Response } from "../../../../_metronic/helpers/crud-helper/models";

const API_URL = process.env.REACT_APP_API_URL

export const DELETE_USER_SESSION = `${API_URL}/v1/ui/session`
export const GET_USER_SESSION = `${API_URL}/v1/ui/session`
export const LOGIN_URL = `${API_URL}/v1/ui/session`
export const REGISTER_URL = `${API_URL}/register`
export const PASSWORD_RESET_URL = `${API_URL}/v1/ui/users/password-resets`
export const REGISTRATION_INVITE_URL = `${API_URL}/v1/ui/users/signup`
export const INVITE_INFO_URL = `${API_URL}/v1/ui/users/invite`
export const FINISH_REGISTRATION_URL = `${API_URL}/v1/ui/users`

type LoginDto = {
  email: string
  password: string
  token: string
}

// Server should return AuthModel
export function login(values: LoginDto) {
  const { token, ...loginDetails } = values;

  return axios
    .post<Omit<LoginDto, "token">, AxiosResponse<Response<AuthModel>>>(LOGIN_URL, loginDetails, {
      // headers: {
      //   "x-captcha-v3": token,
      // }
    })
    .then((d) => d.data)
}

type FinishSignupDto = {
  inviteId: string
  firstName: string
  lastName: string
  password: string
  token: string
}

type FinishSignupDtoResponse = Response<FinishSignupDto>;

export function confirmSignup(values: FinishSignupDto) {
  return axios
    .post<Omit<FinishSignupDto, "token">, AxiosResponse<FinishSignupDtoResponse>>(FINISH_REGISTRATION_URL, values)
    .then(d => d.data);
}

type RegisterDto = {
  email: string
  first_name: string
  last_name: string
  password: string
  password_confirmation: string
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post<RegisterDto, AxiosResponse<Response<AuthModel>>>(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

export function requestPasswordReset(values: RequestPasswordResetDto) {
  return axios
    .post<RequestPasswordResetDto, AxiosResponse<Response<RequestPasswordResetDto>>>(PASSWORD_RESET_URL, values)
    .then((d) => d.data);
}

export function resetPassword({ token, ...values }: ResetPasswordDto) {
  return axios
    .put<Omit<ResetPasswordDto, "token">, AxiosResponse<null>>(`${PASSWORD_RESET_URL}/${token}`, values)
}

export function getUserSession() {
  return axios.get<Response<AuthModel>>(GET_USER_SESSION)
}

export function deleteUserSession() {
  return axios.delete<void>(DELETE_USER_SESSION);
}

type RegisterInviteDto = {
  email: string
  token: string
}

type RegisterInviteResponse = Response<RegisterInviteDto & {
  success: {
    message: string
  }
}>

export function createRegisterationInvite(values: RegisterInviteDto) {
  const { token, ...registrationDetails } = values;

  return axios
    .post<Omit<RegisterInviteDto, "token">, AxiosResponse<RegisterInviteResponse>>(REGISTRATION_INVITE_URL, registrationDetails, {
      // headers: {
      //   "x-captcha-v3": token,
      // }
    })
}

type ValidateInviteID = {
  inviteId: string
}

type UserInvite = {
  inviteId: string
  status: "valid" | "invalid" | "accepted"
}

export function validateInviteId(values: ValidateInviteID) {
  return axios
    .get<Response<UserInvite>>(`${INVITE_INFO_URL}/${values.inviteId}`)
    .then((d) => d.data);
}
